(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/buy-stop-selector/assets/javascripts/buy-stop-selector.js') >= 0) return;  svs.modules.push('/components/marketplace/buy-stop-selector/assets/javascripts/buy-stop-selector.js');
'use strict';

const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const resolveDateBefore = (date, dateBefore) => formatDate(new Date(date - dateBefore), true, true);
const BuyStopSelector = _ref => {
  let {
    className,
    drawCloseTime,
    maxValue,
    onChange,
    value,
    options
  } = _ref;
  const minuteOptions = options.filter(item => item.label.toLowerCase().indexOf('min') !== -1);
  const hoursOptions = options.filter(item => item.label.toLowerCase().indexOf('tim') !== -1);
  const daysOptions = options.filter(item => item.label.toLowerCase().indexOf('dag') !== -1);
  return React.createElement("div", {
    className: "select-label"
  }, React.createElement("select", {
    className: "select select-default ".concat(className),
    "data-testid": "select-buy-stop",
    onChange: e => {
      onChange(parseInt(e.target.value, 10));
    },
    value: "".concat(value)
  }, React.createElement("optgroup", null, minuteOptions.map(_ref2 => {
    let {
      minutes,
      label
    } = _ref2;
    return React.createElement("option", {
      "data-testid": "select-buy-stop-option",
      disabled: minutes > maxValue,
      key: minutes,
      value: minutes
    }, label + (drawCloseTime ? " (".concat(resolveDateBefore(new Date(drawCloseTime).getTime(), minutes * 60 * 1000), ")") : ''));
  })), React.createElement("optgroup", null, hoursOptions.map(_ref3 => {
    let {
      minutes,
      label
    } = _ref3;
    return React.createElement("option", {
      disabled: minutes > maxValue,
      key: minutes,
      value: minutes
    }, label + (drawCloseTime ? " (".concat(resolveDateBefore(new Date(drawCloseTime).getTime(), minutes * 60 * 1000), ")") : ''));
  })), React.createElement("optgroup", null, daysOptions.map(_ref4 => {
    let {
      minutes,
      label
    } = _ref4;
    return React.createElement("option", {
      disabled: minutes > maxValue,
      key: minutes,
      value: minutes
    }, label + (drawCloseTime ? " (".concat(resolveDateBefore(new Date(drawCloseTime).getTime(), minutes * 60 * 1000), ")") : ''));
  }))));
};
BuyStopSelector.defaultProps = {
  className: ''
};
setGlobal('svs.components.marketplace.buyStopSelector.BuyStopSelector', BuyStopSelector);

 })(window);